import React from "react"

import Layout from "../components/layout-maintenance"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Under Maintenance" />
    <section className="kb__404">
      <div className="kb__container--narrow kb--center">
        <h1>We'll be back soon</h1>
        <p>We are currently undergoing a little maintenance, check back shortly.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
